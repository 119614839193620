//
// SVG Icon
//


@mixin svg-icon-size($size, $important: false) {
    svg {
        height: 18px;
        width: 18px;
    }
}
