/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$demo1-primary: mat.define-palette(mat.$indigo-palette);
$demo1-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$demo1-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$demo1-theme: mat.define-light-theme((
  color: (
    primary: #156360,
    accent: #cdcdcd,
    warn: #add,
  )
));

@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
@import "./assets/sass/plugins.scss";
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";


.mat-expansion-indicator{
    background: #fff;
    width: 12px;
    height: 24px;
    padding: 0px 16px 0px 8px;
    border-radius: 50%;
}
.mat-expansion-panel-body{
    padding: 0px !important;
    font-size: inherit !important;
}
.mat-expansion-panel-header{
    padding: 5px 7px !important;
  
  }

.mat-date-range-input-container {
    justify-content: space-between;
    height: 100%;
  }
  .mat-calendar-body-selected {
    background-color: #156360 !important;
    color: $white;
    font-weight: 700 !important;
  }
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #ecfff1 !important;
    color: #156360 !important;
  }
  .bg-ligth-theme{
    background-color: #ecfff1 !important;
  }
    mat-datepicker-content.mat-datepicker-content { 
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    margin-top: 4px !important;
    background: #fff !important;
    // border: 1px solid #156360 !important;
    border-radius: 8px !important
}
.mat-calendar-body-in-range::before {
    background: #ecfff1 !important;
    font-weight: 700 !important;
  }
  .mat-calendar-body-cell::before, .mat-calendar-body-cell::after, .mat-calendar-body-cell-preview {
    height: 80% !important;
    width: 95% !important;
    font-weight: 700 !important;
}

.mat-calendar-body-cell-content {
    width: 80% !important;
    height: 80% !important;
    border-color: #156360 !important;
    border: 0px !important;
}
.mat-datepicker-actions button.btn.px-3.py-2.btn-g-primary {
    padding: calc(0.55rem + 1px) calc(1.25rem + 1px) !important;
    color: #fff !important;
    border-color: var(--kt-secondary) !important;
    background-color: #156360 !important;
    border-radius: 7px;
}
.mat-datepicker-actions button.btn-secondary.me-2.border {
    padding: calc(0.55rem + 1px) calc(1.25rem + 1px) !important;
    color: #3f3f3f !important;
    border-color: var(--kt-secondary) !important;
    background-color: #e1e1e1 !important;
    border-radius: 7px;
}
button.mat-focus-indicator.mat-calendar-period-button.mat-button.mat-button-base {
    background: #ecfff1 !important;
    border: 1px solid #156360 !important;
    color: #156360 !important;
    font-weight: 700 !important;
}
button.mat-focus-indicator.mat-calendar-previous-button.mat-icon-button.mat-button-base,button.mat-focus-indicator.mat-calendar-next-button.mat-icon-button.mat-button-base {
    color: #156360 ! important;
    background: #fff !important;
    border: 1px solid #156360 !important;
}
table.mat-calendar-table .mat-calendar-table-header tr th[scope='col'] {
    background: #ecfff1 !important;
    border: 1px solid #156360 !important;
    padding-top: 9px !important;
    color: #156360 !important;
    font-weight: 700 !important;
}
table.mat-calendar-table tbody.mat-calendar-body{
    color: #156360 !important;
}
input.form-control:focus  + .input-group-text{
    color: var(--kt-input-focus-color);
    background-color: var(--kt-input-focus-bg);
    border-color: var(--kt-input-focus-border-color);
}
  .mat-button-wrapper svg{
    fill:#156360 !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix{
padding: 0PX !important;
  }
  .mat-form-field-infix{
    padding: 0px !important;
    border-top: none !important;
  }
.mat-form-field-wrapper{
    padding: 0px !important;

}
svg.mat-datepicker-toggle-default-icon.ng-star-inserted{
    width: 20px !important;
}
button.mat-focus-indicator.mat-icon-button.mat-button-base{
    width: 33px !important;
    height: 33px !important;
    line-height: 30px !important;
}
body {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: var(--kt-scrollbar-width);
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: var(--kt-scrollbar-border-radius);
}

::-webkit-scrollbar-thumb {
    background: var(--kt-scrollbar-color);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--kt-scrollbar-hover-color);
}

*:focus {
    outline: none;
}

.company-logo {
    margin: 1rem;
    width: 225px;
    display: flex;
    position: absolute;
}

.auth-footer {
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #b5b5c3;
    margin: 1rem;
    position: fixed;
    bottom: 0;
}

.auth-illustration {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: -1;
}

@media (max-width: 412px) {
    .auth-illustration {
        width: 98%;
    }
}

app-layout-wrapper {
    display: contents;
}

.da-card {
    box-shadow: $card-box-shadow !important;
}

fieldset > label {
    position: relative;
    // background: linear-gradient(to top, rgba(0,0,0,0) 50%, var(--kt-card-bg) 50%);
    background-color: var(--kt-card-bg);
    margin: -0.8rem 0.8rem;
    padding: 0 0.5rem;
    top: 8px;
}

.display-grid {
    display: grid;

    i {
        color: inherit;
    }
}

.display-flex {
    display: flex;
    justify-content: space-between;
}

.danger {
    color: var(--kt-danger) !important;
}

.list-style-none {
    list-style: none !important;
}

.btn-shadow:hover {
    box-shadow: $da-btn-box-shadow !important;
}

.toggle-password {
    display: inline;
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 18px !important;
    cursor: pointer !important;
    color: $primary !important;
    z-index: 10;
}

.z-index-20 {
    z-index: 20 !important;
}

.input-group input {
    border-radius: 0.475rem !important;
}

.badge-success {
    color: $white;
    background-color: $state-success;
}

.badge-not-success {
    color: $white;
    background-color: $state-danger;
}

.badge-warning {
    color: $white;
    background-color: $state-warning;
}

.badge-did-not {
    color: $white;
    background-color: $state-did-not;
}

.badge-partially {
    color: $font1-da;
    background-color: $state-partially;
}

.badge-assigned {
    color: $font1-da;
    background-color: $state-assigned;
}

.badge-eligible {
    color: $font1-da;
    background-color: $state-eligible;
}

.badge-not-found {
    color: $font1-da;
    background-color: $state-not-found;
}

.badge-not-eligible {
    color: $font1-da;
    background-color: $state-not-eligible;
}

.badge-pending {
    color: $font1-da;
    background-color: $state-pending;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

.vertical-separator {
    width: 20px;
    height: 0px;
    border: 1px solid #e5e5e5;
    transform: rotate(90deg);
    flex: none;
    order: 1;
    flex-grow: 0;
}

.dropdown-icon {
    transition-timing-function: linear;
    transition: transform 0.5s;
}

.menu-dropdown {
    .dropdown-icon {
        transform: rotate(180deg);
    }
}

.menu-container {
    min-width: 175px;
    width: max-content;
    max-height: 300px;
    overflow-x: auto;
}

.grid-filter-bar {
    // position: relative;
    // display: flex;
    margin-bottom: 1rem;
    // justify-content: space-between;

    .grid-tool-bar {
        position: relative;
        div {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}

.da-placeholder {
    color: var(--kt-input-placeholder-color);
}

.claimsLabel{
    color:#888888 !important
}
button.btn.btn-shadow.btn-outline.btn-outline-primary:disabled {
    color: rgb(105, 103, 103) !important;
    border-color:#cdcdcd !important;
}

button.mat-focus-indicator.mat-tooltip-trigger.mat-icon-button.mat-button-base.mat-button-disabled>.mat-button-wrapper>svg{
    fill:#cdcdcd !important;
}



.badge-success {
    color: $white;
    background-color: $state-success;
  }
  
  .badge-not-success {
    color: $not-success;
    background-color: $state-danger-bg;
  }
  
  .badge-warning {
    color: $white;
    background-color: $state-warning;
  }
  
  .badge-did-not {
    color: $white;
    background-color: $state-did-not;
  }
  
  .badge-partially {
    color: $font1-da;
    background-color: $state-partially;
  }
  
  .badge-assigned {
    color: $font1-da;
    background-color: $state-assigned;
  }
  
  .badge-eligible {
    color: $eligible;
    background-color: $state-eligible-bg;
  }
  
  .badge-not-found {
    color: $not-found;
    background-color: $state-not-found-bg;
  }
  
  .badge-not-eligible {
    color: $not-eligible;
    background-color: $state-not-eligible-bg;
  }
  
  .badge-pending {
    color: $pending;
    background-color: $state-pending-bg;
  }
  .badge-undetermined {
    color: $undetermined;
    background-color: $state-undetermined-bg;
  }
  .badge-partially-eligible {
    color: $partially-eligible;
    background-color: $state-partially-eligible-bg;
  }

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
