//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// DA colors
$primary-da: #156360;
$primary-da-dark: #0a4947;
$secondary-da: #12b47f;
$tertiary-da: #f0fbf1;

$font1-da: #222222;
$font2-da: #b6b6b6;
$font3-da: #8b8b8b;

$icon-da: #1d1130;

$line1-da: #e5e5e5;
$line2-da: #c4c4c4;
$line3-da: #f3f6f9;

$chart1-da: #45eba5;
$chart2-da: #21aba5;
$chart3-da: #98c74e;
$chart4-da: #357a5b;
$chart5-da: #60a261;
$chart6-da: #ccf62c;
$chart7-da: $primary-da;
$chart8-da: #31bccc;
$chart9-da: #324b4b;
$chart10-da: #9aebdd;
$chart11-da: #367db5;
$chart12-da: #313e86;

$state-success: $secondary-da;
$state-danger: #ff6363;
$state-warning: #ffb440;
$state-did-not: $font2-da;
$state-partially: #cbffd2;
$state-assigned: #91e8e1;
$state-eligible: #6ed47c;
$state-not-found: #e5e5e5;
$state-not-eligible: #ff6767;
$state-pending: #fec061;

$da-btn-box-shadow: 0px 9px 16px 0px rgb(10 73 71 / 30%);

// Theme colors
// Primary
$primary: $primary-da;
$primary-active: $primary-da-dark;
$primary-light: #f1faff;
$primary-light-dark: #212e48;
$primary-inverse: $white;

$secondary: $secondary-da;
$secondary-active: $secondary-da;
$secondary-light: #f1faff;
$secondary-light-dark: #212e48;
$secondary-inverse: $white;

// Success
$success: $state-success;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: $state-danger;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: $state-warning;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;

$gray-700: $font1-da !default;
$gray-500: #1d1130 !default;
$app-sidebar-base-box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05) !default;
$app-sidebar-base-box-shadow-mobile: 0px 10px 30px 0px rgba(82, 63, 105, 0.05) !default;
$headings-color: $primary !default;
$input-placeholder-color: $font2-da !default;
$input-focus-border-color: $secondary !default;
$toast-max-width: 380px !default;

$app-content-padding-y: 10px !default;
$app-content-padding-y-mobile: 10px !default;
$app-container-padding-x: 10px !default;
$app-container-padding-x-mobile: 10px !default;

// $form-check-input-indeterminate-bg-color: transparent !default;
// $form-check-input-bg-solid: transparent !default;
// $form-check-input-checked-bg-color-solid: transparent !default;
// $form-switch-color-solid: $form-check-input-focus-border !default;
// $form-switch-checked-color: $form-check-input-focus-border !default;
// $form-check-input-checked-color: $form-check-input-focus-border !default;
// $form-switch-bg-image-solid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-solid}'/></svg>") !default;
// $form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
// $form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'><path d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='#{$form-check-input-checked-color}'/></svg>") !default;

//Badge colors
$not-success: #FF6767;
$eligible: #06AC76;
$pending: #FFA902;
$not-found: #8B8B8B;
$not-eligible:#cd3838;
$undetermined: #45458f;
$partially-eligible: #176437;

//badge background
$state-danger-bg: #FDD5D5;
$state-eligible-bg: #D8FFDD;
$state-pending-bg: #FFE6B6;
$state-not-found-bg: #F3F3F3;
$state-not-eligible-bg:#ed8f8f;
$state-undetermined-bg: #d0d0ffcf;
$state-partially-eligible-bg: #a4e7bacf;
