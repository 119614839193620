//
// Custom Header(used by Light Sidebar layout only)
//

[data-kt-app-layout="light-sidebar"] {
    // Desktop mode
    @include media-breakpoint-up(lg) {
        .app-header-menu {
            .menu {
                // Menu root item
                > .menu-item {
                    @include menu-link-default-state(
                        $title-color: var(--kt-gray-700),
                        $icon-color: var(--kt-gray-500),
                        $bullet-color: var(--kt-gray-500),
                        $arrow-color: var(--kt-gray-500),
                        $bg-color: null
                    );

                    @include menu-link-hover-state(
                        $title-color: $white,
                        $icon-color: $white,
                        $bullet-color: $white,
                        $arrow-color: $white,
                        $bg-color: var(--kt-primary)
                    );

                    @include menu-link-show-state(
                        $title-color: $white,
                        $icon-color: $white,
                        $bullet-color: $white,
                        $arrow-color: $white,
                        $bg-color: var(--kt-primary)
                    );

                    @include menu-link-here-state(
                        $title-color: $white,
                        $icon-color: $white,
                        $bullet-color: $white,
                        $arrow-color: $white,
                        $bg-color: var(--kt-primary)
                    );

                    @include menu-link-active-state(
                        $title-color: $white,
                        $icon-color: $white,
                        $bullet-color: $white,
                        $arrow-color: $white,
                        $bg-color: var(--kt-primary)
                    );
                }
            }
        }
    }
}

// Desktop mode
@include media-breakpoint-up(lg) {
    [data-kt-app-layout="light-sidebar"]:not([data-kt-app-header-fixed="true"]) {
        .app-header {
            background-color: transparent;
            box-shadow: none;
            border-bottom: 1px solid var(--kt-app-sidebar-light-separator-color);
        }
    }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
    [data-kt-app-layout="light-sidebar"]:not([data-kt-app-header-fixed-mobile="true"]) {
        .app-header {
            background-color: transparent;
            box-shadow: none;
            border-bottom: 1px solid var(--kt-app-sidebar-light-separator-color);
        }
    }
}
